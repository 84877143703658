
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex'
import SuitePlanCard from './SuitePlanCard.vue';
import PlanSelector from '@/components/PlanSelector.vue';
export default defineComponent({
  name: 'Contracts',
  components: {
    SuitePlanCard,
    PlanSelector,
  },
  computed: {
    ...mapGetters([
      'allCreditsPlan'
    ])
  },
  data() {
    return {
    }
  },
  methods: {
  },
})
